<template>
  <router-view/>
</template>

<style lang="scss">

</style>

<script>

export default {
  name: 'App',
  created:  function () {
  }
}
</script>
