const lobby = {
    state: () => ({
        games: {},
        joinedGame: 0,
        isReady: false,
    }),
    getters: {},
    mutations: {
        addGame: (state, game) => {
            if (game.players > 0) {
                state.games[game.gameId] = game;
            }
        },
        updateGame: (state, game) => {
            if (game.players > 0) {
                state.games[game.gameId] = game;
            } else {
                delete state.games[game.gameId]
            }
        },
        joinGame: (state, game) => {
            state.joinedGame = game.gameId;
        },
        leaveGame: (state) => {
            state.joinedGame = 0;
            state.isReady = false;
        },
        ready: (state) => {
            state.isReady = true;
        },
        gameFinished: (state) => {
            delete state.games[state.joinedGame];
            state.joinedGame = 0;
            state.isReady = false;
        },
    },
    actions: {
        onMessage: (store, { type, payload }) => {
            switch (type) {
                case 'resp:list':
                    for (const gameId in payload) {
                        store.commit('addGame', payload[gameId]);
                    }
                    break;
                case 'resp:join':
                    store.commit('joinGame', payload);
                    break;
                case 'event:join':
                    store.commit('updateGame', payload);
                    break;
                case 'resp:leave':
                    store.commit('leaveGame');
                    break;
                case 'event:leave':
                    store.commit('updateGame', payload);
                    break;
                case 'resp:ready':
                    store.commit('ready');
                    break;
                case 'event:ready':
                    store.commit('updateGame', payload);
                    break;
                case 'event:game.end':
                    store.commit('gameFinished');
                    break;
            }
        }
    }
}

export default lobby;
