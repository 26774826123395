<template>
  <Nav></Nav>
  <GameList></GameList>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Nav.vue'
import GameList from "@/components/lobby/GameList";

export default {
  name: 'Home',
  components: {
    Nav,
    GameList
  }
}
</script>
