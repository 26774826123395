import { createStore } from 'vuex'
import lobby from "@/store/lobby";
import game from "@/store/game";
import notifications from "@/store/notifications";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    lobby,
    game,
    notifications
  }
})
