function getDefaultState() {
    return {
        notifications: [],
    };
}

const notifications = {
    state: () => (getDefaultState()),
    getters: {},
    mutations: {
        clear(state) {
            Object.assign(state, getDefaultState());
        },
        addNotification(state, notification) {
            state.notifications.unshift(notification);
        },
        removeNotification(state) {
            state.notifications.pop();
        }
    },
    actions: {
        onMessage(store, { type, payload }) {
            switch (type) {
                case 'event:game.start':
                    store.commit('clear');
                    return;
                case 'event:game.nodebuilt':
                    store.commit('addNotification', 'New node built');
                    return;
                case 'event:game.enemynodebuilt':
                    store.commit('addNotification', 'New enemy node built');
                    return;
                case 'event:game.nodeupgraded':
                    store.commit('addNotification', 'Node upgrade completed');
                    return;
                case 'event:game.retrofitcompleted':
                    if (payload.type === 'shield') {
                        store.commit('addNotification', 'Shield retrofit completed');
                    }
                    if (payload.type === 'weapon') {
                        store.commit('addNotification', 'Weapon retrofit completed');
                    }
                    return;
                case 'event:game.researchcompleted':
                    if (payload.type === 'shield') {
                        store.commit('addNotification', 'Shield research completed');
                    }
                    if (payload.type === 'weapon') {
                        store.commit('addNotification', 'Weapon research completed');
                    }
                    return;
                case 'event:game.nodedestroyed':
                    store.commit('addNotification', 'Node destroyed');
                    return;
                case 'event:game.enemynodedestroyed':
                    store.commit('addNotification', 'Enemy node destroyed');
                    return;
            }

            setTimeout(() => {
                store.commit('removeNotification');
            }, 7000);
        },
    }
}

export default notifications;
