import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import webSocket from '@/services/websocket'
import gameMap from "@/services/gamemap";

require('@/assets/base.css');
require('@/assets/tailwind.css')

createApp(App).
    use(store).
    use(router).
    use(webSocket, {store, url: "wss://nodes.fallofempires.com/ws"}).
    use(gameMap).
    mount('#app')


