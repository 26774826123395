import {inject} from "vue";

let webSocket = {}

webSocket.install = function (Vue, options) {
    let ws = new WebSocket(options.url)
    let id = 0;
    let cmdQueue = [];
    let connected = false;
    let cmds = {};
    let extraData = {};
    //let reconnectInterval = options.reconnectInterval || 1000

    ws.onopen = () => {
        // Restart reconnect interval
        //reconnectInterval = options.reconnectInterval || 1000
        cmdQueue.forEach(function (cmd) {
            ws.send(cmd);
        });

        cmdQueue = [];
        connected = true;
    }

    ws.onmessage = (event) => {
        let data;
        data = JSON.parse(event.data);

        if ('event' in data) {
            if (data.event === 'game') {
                data = data.payload;
                data.event = 'game.' + data.event;
            }
            options.store.dispatch('onMessage', {type: 'event:' + data.event, payload: data.payload})
        }

        if ('error' in data) {
            if (typeof data['payload'] === 'string') {
                alert(data['payload']);
            } else {
                alert(data['payload']['payload']);
            }

            delete cmds[data['error']];
        }

        if ('resp' in data) {
            if (typeof extraData[data['resp']] !== 'undefined') {
                Object.assign(data.payload, extraData[data['resp']]);
            }

            options.store.dispatch('onMessage', {type: 'resp:' + cmds[data.resp], payload: data.payload})
            delete cmds[data['resp']];
            delete extraData[data['resp']];
        }
    }

    /*ws.onclose = (event) => {
        if (event) {
            // Event.code 1000 is our normal close event
            if (event.code !== 1000) {
                let maxReconnectInterval = options.maxReconnectInterval || 3000
                setTimeout(() => {
                    if (reconnectInterval < maxReconnectInterval) {
                        // Reconnect interval can't be > x seconds
                        reconnectInterval += 1000
                    }
                    Vue.prototype.$webSocketsConnect()
                }, reconnectInterval)
            }
        }
    }*/

    ws.onerror = (error) => {
        console.log(error)
        ws.close()
    }

    const sendCmd = (type, data = {}) => {
        // Send data to the backend - use JSON.stringify(data)
        if (connected) {
            ws.send(JSON.stringify({'cmd': type, 'id': id, 'payload': data}))
        } else {
            cmdQueue.push(JSON.stringify({'cmd': type, 'id': id, 'payload': data}));
        }
        cmds[id] = type;
        id++;
    };

    const sendGameCmd = (type, data = {}) => {
        let cmdId = id;
        sendCmd('game', {'cmd': type, 'payload': data});
        cmds[cmdId] = 'game.' + type;
        extraData[cmdId] = {node: data.node};
    }

    Vue.provide('$sendCmd', sendCmd);
    Vue.provide('$sendGameCmd', sendGameCmd);
    setInterval(function () {sendCmd('heartbeat');}, 30000);
}

export default webSocket

export function sendCmd(type, payload) {
    const sendCmd = inject('$sendCmd');
    sendCmd(type, payload);
}
