<template>
  <div id="nav" class="md:p-6 sm:p-3">
    <router-link to="/">Lobby</router-link> |
    <router-link to="/how-to-play">How To Play</router-link>
  </div>
</template>

<script>
export default {
  name: 'Nav',
}
</script>

<style lang="css">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
 color: rgba(255,0,0,0.75);
}

</style>
