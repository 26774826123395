import router from "@/router";

function getDefaultState() {
    return {
        nodes: {},
        scores: {},
        playerIndex: 0,
        shields: [],
        weapons: [],
        inProgress: false,
        projectiles: {},
        projectileId: 0
    };
}

function makeNodeHash(playerId, nodeId) {
    return playerId + '.' + nodeId;
}

const game = {
    state: () => (getDefaultState()),
    getters: {},
    mutations: {
        reset(state) {
            Object.assign(state, getDefaultState());
        },
        players(state, { player, players }) {
            state.playerIndex = player;
            for (const playerIdx in players) {
                state.scores[players[playerIdx]['id']] = 0;
            }
            state.inProgress = true;
        },
        score(state, { player, score }) {
            state.scores[player] = score;
        },
        newNode(state, node) {
            const nodeHash = makeNodeHash(node.playerId, node.id);
            state.nodes[nodeHash] = node;
            state.nodes[nodeHash].nodeHash = nodeHash;
            state.nodes[nodeHash].busy = false;
        },
        updateNode(state, node) {
            const nodeHash = makeNodeHash(node.playerId, node.id);
            Object.assign(state.nodes[nodeHash], node);
        },
        destroyNode(state, nodeHash) {
            delete state.nodes[nodeHash];
        },
        nodeBusy(state, nodeHash) {
            state.nodes[nodeHash].busy = true;
        },
        nodeIdle(state, nodeHash) {
            state.nodes[nodeHash].busy = false;
        },
        shield(state, item) {
            state.shields.unshift(item);
        },
        weapon(state, item) {
            state.weapons.unshift(item);
        },
        projectile(state, projectile) {
            state.projectiles[state.projectileId] = projectile;
            state.projectiles[state.projectileId].id = state.projectileId;
            state.projectileId++;
        },
        removeProjectile(state, index) {
            delete state.projectiles[index];
        }
    },
    actions: {
        removeProjectile(store, index) {
            store.commit('removeProjectile', index)
        },
        onMessage(store, { type, payload }) {
            switch (type) {
                case 'event:game.start':
                    store.commit('reset');
                    store.commit('players', payload);
                    router.push('/play');
                    return;
                case 'event:game.score':
                    store.commit('score', payload);
                    return;
                case 'event:game.nodebuilt':
                    Object.assign(payload.node, {playerId: store.state.playerIndex});
                    store.commit('newNode', payload.node);
                    return;
                case 'event:game.enemynodebuilt':
                    store.commit('newNode', {id: payload.nodeid, position: payload.position, playerId: payload.player});
                    return;
                case 'event:game.nodeupgraded':
                case 'event:game.retrofitcompleted':
                case 'event:game.noderegenerated':
                    Object.assign(payload.node, {playerId: store.state.playerIndex});
                    store.commit('updateNode', payload.node);
                    return;
                case 'event:game.nodeidle':
                    store.commit('nodeIdle', makeNodeHash(store.state.playerIndex, payload.node.id));
                    return;
                case 'event:game.researchcompleted':
                    store.commit(payload.type, payload.item);
                    return;
                case 'event:game.nodeattacked':
                    store.commit('projectile', {
                        from: store.state.nodes[makeNodeHash(payload.byPlayer, payload.byId)].position,
                        to: store.state.nodes[makeNodeHash(store.state.playerIndex, payload.node.id)].position,
                        player: payload.byPlayer
                    });
                    return;
                case 'event:game.attackednode':
                    store.commit('projectile', {
                        from: store.state.nodes[makeNodeHash(store.state.playerIndex, payload.byNode.id)].position,
                        to: store.state.nodes[makeNodeHash(payload.player, payload.node)].position,
                        player: store.state.playerIndex
                    });
                    return;
                case 'event:game.nodedestroyed':
                    store.commit('destroyNode', makeNodeHash(store.state.playerIndex, payload.node.id));
                    return;
                case 'event:game.enemynodedestroyed':
                    store.commit('destroyNode', makeNodeHash(payload.player, payload.nodeid));
                    return;
                case 'event:game.end':
                    router.push('/gameover');
                    return;
                case 'resp:game.upgrade':
                case 'resp:game.research':
                case 'resp:game.build':
                case 'resp:game.retrofit':
                    store.commit('nodeBusy', makeNodeHash(store.state.playerIndex, payload.node));
                    return;
            }
        }
    }
}

export default game;
