<template>
  <div class="container mx-auto">
    <div class="p-2 flex justify-end nodes-box nodes-box-hv">
      <a v-on:click="newGame()" class="nodes-text-white text-5xl mr-auto">New Game</a>
    </div>
    <div v-for="game in games" :key="game.gameId" class="p-2 flex justify-end nodes-box nodes-box-hv">
      <div class="nodes-text-white text-5xl mr-auto">{{ game.map }} Default</div>
      <a v-show="showJoin()" v-on:click="join(game.gameId)" class="nodes-box-blank nodes-text-red p-2 ml-2 mr-2 w-1/12 text-center">Join</a>
      <a v-show="showLeave(game.gameId)" v-on:click="leave(game.gameId)" class="nodes-box-blank nodes-text-red p-2 ml-2 mr-2 w-1/12 text-center">Leave</a>
      <a v-show="showReady(game.gameId)" v-on:click="ready(game.gameId)" class="nodes-box-blank nodes-text-red p-2 ml-2 mr-2 w-1/12 text-center">Ready</a>
      <div class="nodes-text-red ml-2 mr-2 w-1/12 text-center">Players <br /> {{ game.players }}/{{ game.minPlayers }} ({{ game.maxPlayers }})</div>
    </div>
  </div>
</template>

<script>
  import { useStore } from 'vuex'
  import { inject } from "vue";

  export default {
  name: 'GameList',
  inject: ['$sendCmd'],
  computed: {
    games () {
      const store = useStore();
      return store.state.lobby.games;
    },
    joinedGame () {
      const store = useStore();
      return store.state.lobby.joinedGame;
    },
    isReady () {
      const store = useStore();
      return store.state.lobby.isReady;
    }
  },
  setup:  function () {
    inject('$sendCmd')('list')
  },

  methods: {
    showJoin() {
      return this.joinedGame === 0;
    },
    showLeave(gameId) {
      return this.hasJoined(gameId);
    },
    showReady(gameId) {
      return this.hasJoined(gameId) && !this.isReady;
    },

    hasJoined(gameId) {
      return this.joinedGame === gameId;
    },

    join(gameId) {
      this.$sendCmd('join', gameId)
    },
    leave(gameId) {
      this.$sendCmd('leave', gameId)
    },
    ready(gameId) {
      this.$sendCmd('ready', gameId)
    },
    newGame() {
      this.$sendCmd('join', [])
    }
  }
}
</script>
