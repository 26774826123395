let gameMap = {}

gameMap.install = function (Vue) {
    const props = {
        zoom: 0.2,
        scale: 30,
        xMin: -130,
        xMax: 110,
        yMin: -10,
        yMax: 110
    };

    Vue.provide('$gameMap', props);
}

export default gameMap
